import {
  FETCH_PRESCRIPTION_UTILITIES,
  CREATE_PRESCRIPTION,
  FETCH_PRESCRIPTION,
  UPDATE_PRESCRIPTION,
  FETCH_PRESCRIPTIONS,
  DELETE_PRESCRIPTION,
  RESET_PRESCRIPTION,
} from "actions/prescriptions/types";
import { insertItem, updateObjectInArray } from "helpers/helpers";

/**
 * @param state
 * @param action
 */
export const prescriptionUtilities = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case FETCH_PRESCRIPTION_UTILITIES:
      return { ...action.payload };
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export default function prescriptions(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case CREATE_PRESCRIPTION:
      return {
        ...state,
        data: insertItem(state.data, action.payload.data),
      };
    case UPDATE_PRESCRIPTION:
      return {
        ...state,
        data: updateObjectInArray(state.data, action.payload.data),
      };
    case FETCH_PRESCRIPTION:
      return { ...state, prescription: action.payload.data };
    case RESET_PRESCRIPTION:
      return { ...state, prescription: null };
    case FETCH_PRESCRIPTIONS:
      return { ...action.payload };
    case DELETE_PRESCRIPTION:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}
