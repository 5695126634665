import { mapKeys, omit } from "lodash";
import {
  FETCH_PATIENT_APPOINTMENTS,
  PATIENT_CLINIC_SEARCH,
  PATIENT_APPOINTMENT_SELECTION,
  RESET_PATIENT_APPOINTMENT_SELECTION,
  PATIENT_CLINIC_SLOTS,
  PATIENT_APPOINTMENT_LOBBY_CHECK,
  PATIENT_FETCH_ATTACHMENTS,
  PATIENT_FETCH_ATTACHMENT,
  PATIENT_CREATE_ATTACHMENT,
  PATIENT_UPDATE_ATTACHMENT,
  PATIENT_DELETE_ATTACHMENT,
  PATIENT_LOAD_ROOM_DATA,
  PATIENT_APPOINTMENT_RESET_DATA,
  PATIENT_ROOM_FINISH,
  FETCH_APPOINTMENT_PRICE,
  PATIENT_FETCH_APPOINTMENT,
  PATIENT_FETCH_APPOINTMENT_RECOMMENDATIONS,
	PATIENT_TIME_LINE
} from "actions/appointments/types";

/**
 * @param state
 * @param action
 * @returns {*}
 */
export default function patientAppointments(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case FETCH_PATIENT_APPOINTMENTS:
      return { ...action.payload };
    case PATIENT_FETCH_APPOINTMENT:
      return { appointment: action.payload.data };
    case PATIENT_FETCH_APPOINTMENT_RECOMMENDATIONS:
      return { ...state, recommendations: action.payload.recommendations };
    default:
      return state;
  }
}
export const patientClinicSearch = (state: Objec = {}, action: Object) => {
  switch (action.type) {
    case PATIENT_CLINIC_SEARCH:
      return { ...mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export const patientClinicSlots = (state: Objec = {}, action: Object) => {
  switch (action.type) {
    case PATIENT_CLINIC_SLOTS:
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

export const patientAppointmentSelection = (
  state: Objec = {},
  action: Object
) => {
  switch (action.type) {
    case PATIENT_APPOINTMENT_SELECTION:
      return { ...state, ...action.payload };
    case FETCH_APPOINTMENT_PRICE:
      return {
        ...state,
        price: action.payload.price,
        cause: action.payload.cause,
      };
    case RESET_PATIENT_APPOINTMENT_SELECTION:
      return {};
    default:
      return state;
  }
};

export const patientAppointmentLobbyCheck = (
  state: Objec = {},
  action: Object
) => {
  switch (action.type) {
    case PATIENT_APPOINTMENT_LOBBY_CHECK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const patientAppointmentAttachments = (
  state: Objec = {},
  action: Object
) => {
  switch (action.type) {
    case PATIENT_FETCH_ATTACHMENTS:
      return { ...mapKeys(action.payload, "id") };
    case PATIENT_CREATE_ATTACHMENT:
    case PATIENT_FETCH_ATTACHMENT:
    case PATIENT_UPDATE_ATTACHMENT:
      return { ...state, [action.payload.id]: action.payload };
    case PATIENT_DELETE_ATTACHMENT:
      return omit(state, action.payload);
    case PATIENT_APPOINTMENT_RESET_DATA:
      return {};
    default:
      return state;
  }
};

export const patientRoomData = (state: Objec = {}, action: Object) => {
  switch (action.type) {
    case PATIENT_LOAD_ROOM_DATA:
      return { ...action.payload };
    case PATIENT_ROOM_FINISH:
      return { ...action.payload };
    default:
      return state;
  }
};

export const patientTimeLine = (state: Objec = {}, action: Object) => {
  switch (action.type) {
    case PATIENT_TIME_LINE:
      return { ...action.payload };
    default:
      return state;
  }
};

