import { ICD_AUTH, FETCH_APPOINTMENT_ICDS } from "actions/icd/types";

/**
 * @param state
 * @param action
 */
export default function icd(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case ICD_AUTH:
      return { ...state, auth: action.payload };
    case FETCH_APPOINTMENT_ICDS:
      return { ...state, icd_list: action.payload.data };
    default:
      return state;
  }
}
