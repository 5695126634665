import {
  LIST_DOCTORS,
  CLEAR_DOCTORS_LIST,
  DOCTOR_LOGGED_IN,
  DOCTOR_LOGGED_OUT,
  LIST_CONSULTANT_DOCTORS,
} from "actions/doctors/types";

/**
 * @param state
 * @param action
 */

export const consultantDoctors =(state: Object = {}, action: Object)=> {
  switch (action.type) {
    case LIST_CONSULTANT_DOCTORS:
      return {
        ...state.consultantDoctors,
        consultantDoctors: {
          ...state.consultantDoctors,
          [action.payload.index]: action.payload.data
        }
      };
    default:
      return state;
  }
}
export default function doctors(state: Object = {}, action: Object): Object {
  let data = [];
  switch (action.type) {
    case LIST_DOCTORS:
    case CLEAR_DOCTORS_LIST:
      return { ...action.payload };
          case DOCTOR_LOGGED_IN:
      if (state.data) {
        data = state.data.filter((item) => item.id !== action.payload.id);
        data = [...data, action.payload];
      } else {
        data = [action.payload];
      }
      return { ...state, data };
    case DOCTOR_LOGGED_OUT:
      if (state.data) {
        return {
          ...state,
          data: state.data.filter((item) => item.id !== action.payload.id),
        };
      }
    default:
      return state;
  }
}
