export const FETCH_SPECIALTIES_FORM_UTILITIES =
  "FETCH_SPECIALTIES_FORM_UTILITIES";
export const CREATE_SPECIALTIES_FORM = "CREATE_SPECIALTIES_FORM";
export const FETCH_SPECIALTIES_FORM = "FETCH_SPECIALTIES_FORM";
export const LIST_SPECIALTIES_FORMS = "LIST_SPECIALTIES_FORMS";
export const LIST_CONDITION_SPECIALTIES_FORMS = "LIST_CONDITION_SPECIALTIES_FORMS";
export const LIST_ADMIN_SPECIALTIES_FORMS = "LIST_ADMIN_SPECIALTIES_FORMS";
export const UPDATE_SPECIALTIES_FORM = "UPDATE_SPECIALTIES_FORM";
export const FETCH_SPECIALTIES_FORMS = "FETCH_SPECIALTIES_FORMS";
export const DELETE_SPECIALTIES_FORM = "DELETE_SPECIALTIES_FORM";
export const RESET_SPECIALTIES_FORM = "RESET_SPECIALTIES_FORM";
export const FETCH_PUBLIC_SUBMISSIONS = "FETCH_PUBLIC_SUBMISSIONS";
export const FETCH_PUBLIC_SUBMISSION = "FETCH_PUBLIC_SUBMISSION";
export const CREATE_PUBLIC_SUBMISSIONS = "CREATE_PUBLIC_SUBMISSIONS";
export const UPDATE_PUBLIC_SUBMISSIONS = "UPDATE_PUBLIC_SUBMISSIONS";
export const CREATE_SENT_PUBLIC_SURVEY = "CREATE_SENT_PUBLIC_SURVEY";
export const UPDATE_SENT_PUBLIC_SURVEY = "UPDATE_SENT_PUBLIC_SURVEY";
