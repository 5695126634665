import {
  CREATE_SPECIALTY,
  FETCH_SPECIALTY,
  UPDATE_SPECIALTY,
  FETCH_SPECIALTIES,
  DELETE_SPECIALTY,
} from "actions/specialties/types";

/**
 * @param state
 * @param action
 */
export default function specialties(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case CREATE_SPECIALTY:
      return { specialty: action.payload.data, isCreated: true };
    case FETCH_SPECIALTY:
      return { specialty: action.payload.data };
    case UPDATE_SPECIALTY:
      return { specialty: action.payload.data, isUpdated: true };
    case FETCH_SPECIALTIES:
      return { ...action.payload };
    case DELETE_SPECIALTY:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}
