import {
  FETCH_MEDICAL_HISTORY,
  UPDATE_MEDICAL_HISTORY,
} from "actions/episodes/types";

const medicalHistory = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEDICAL_HISTORY:
    case UPDATE_MEDICAL_HISTORY:
      return { ...action.payload };
    default:
      return state;
  }
};

export default medicalHistory;
