import { START_CLINIC } from "actions/clinics/types";
import { mapKeys, omit } from "lodash";
import {
  FETCH_LOBBY,
  FETCH_LOBBY_APPOINTMENTS,
  PATIENT_JOINED_LOBBY,
  PATIENT_LEAVED_LOBBY,
  LIST_JOINED_PATIENTS,
} from "actions/lobby/types";

/**
 * @param state
 * @param action
 */
const lobby = (state: Object = { patients: [] }, action: Object) => {
  switch (action.type) {
    case START_CLINIC:
    case FETCH_LOBBY:
      return { ...state, lobby: action.payload };
    case FETCH_LOBBY_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case PATIENT_JOINED_LOBBY: {
      const newPatients = state.patients;
      newPatients[action.payload.id] = action.payload;
      return {
        ...state,
        patients: newPatients,
      };
    }
    case PATIENT_LEAVED_LOBBY:
      console.log("reducer",PATIENT_LEAVED_LOBBY, action.payload, state.patients)
      return {
        ...state,
        patients: omit(state.patients, action.payload),
      };
    case LIST_JOINED_PATIENTS:
      return {
        ...state,
        patients: mapKeys(action.payload, "id"),
      };

    default:
      return state;
  }
};

export default lobby;
