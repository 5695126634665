import {
  CREATE_HOSPITAL,
  FETCH_HOSPITAL,
  UPDATE_HOSPITAL,
  FETCH_HOSPITALS,
  DELETE_HOSPITAL,
  FETCH_HOSPITAL_STAFF,
  MY_HOSPITALS,
  FETCH_HOSPITAL_EXCLUDED_USERS,
  FETCH_HOSPITAL_LOGO,
  FETCH_HOSPITAL_AGREEMENT,
  DELETE_HOSPITAL_MEMBER,
} from "actions/hospitals/types";

/**
 * @param state
 * @param action
 */
export default function hospitals(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_HOSPITAL:
    case UPDATE_HOSPITAL:
      return { hospital: action.payload.data, redirect: true };
    case FETCH_HOSPITAL:
      return { hospital: action.payload.data };
    case FETCH_HOSPITALS:
      return { ...action.payload };
    case DELETE_HOSPITAL:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case FETCH_HOSPITAL_STAFF:
      return { ...state, staff: { ...action.payload } };
    case DELETE_HOSPITAL_MEMBER:
      return {
        ...state,
        staff: {
          ...state.staff,
          data: state.staff.data.filter((item) => item.id !== action.payload),
        },
      };
    case MY_HOSPITALS:
      return { ...state, myHospitals: { ...action.payload } };

    case FETCH_HOSPITAL_EXCLUDED_USERS:
      return { ...state, excludedUsers: action.payload.data };

    case FETCH_HOSPITAL_LOGO:
      return { ...state, Logo: action.payload.data };
    case FETCH_HOSPITAL_AGREEMENT:
      return { ...state, agreement: action.payload.data };
    default:
      return state;
  }
}
