import {
  FETCH_FOLLOW_UP_UTILITIES,
  CREATE_FOLLOW_UP,
  FETCH_FOLLOW_UP,
  UPDATE_FOLLOW_UP,
  FETCH_FOLLOW_UPS,
  DELETE_FOLLOW_UP,
} from "actions/follow-ups/types";
import { insertItem, updateObjectInArray } from "helpers/helpers";

/**
 * @param state
 * @param action
 */
export const followUpUtilities = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case FETCH_FOLLOW_UP_UTILITIES:
      return { ...action.payload };
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export default function followUps(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_FOLLOW_UP:
      return {
        ...state,
        data: insertItem(state.data, action.payload.data),
      };
    case UPDATE_FOLLOW_UP:
      return {
        ...state,
        data: updateObjectInArray(state.data, action.payload.data),
        followUp: {},
      };
    case FETCH_FOLLOW_UP:
      return { ...state, followUp: action.payload.data };
    case FETCH_FOLLOW_UPS:
      return { ...action.payload };
    case DELETE_FOLLOW_UP:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}
