import { TOGGLE_SIDEBAR } from "constants/constants";

/**
 * @param state
 * @param action
 */

/**
 * @param sidebarShowValue
 * @param action
 */
export default function sideBarShow(sidebarShowValue = "responsive", action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      console.log("actions", action);
      return action.sidebarShow;
    default:
      return sidebarShowValue;
  }
}
