export const CREATE_ROUND = "CREATE_ROUND";
export const FETCH_ROUND = "FETCH_ROUND";
export const FETCH_USER_ROUND = "FETCH_USER_ROUND";
export const UPDATE_ROUND = "UPDATE_ROUND";
export const FETCH_ROUNDS = "FETCH_ROUNDS";
export const DELETE_ROUND = "DELETE_ROUND";
export const FETCH_ROUND_UTILITIES = "FETCH_ROUND_UTILITIES";
export const FETCH_MY_ROUNDS = "FETCH_MY_ROUNDS";
export const FETCH_ROUND_PATIENTS = "FETCH_ROUND_PATIENTS";
export const FETCH_PUBLIC_ROUNDS = "FETCH_PUBLIC_ROUNDS";
export const START_ROUND = "START_ROUND";
export const GET_CONFERENCE_DATA = "GET_CONFERENCE_DATA";
export const FILTER_CASES = "FILTER_CASES";
export const FETCH_ATTENDEE_ROUNDS = "FETCH_ATTENDEE_ROUNDS";
export const SAVE_CONSULTATION_NOTE = "SAVE_CONSULTATION_NOTE";
export const HIDE_CONSULTATION_NOTE_NOTIFICATION = "HIDE_CONSULTATION_NOTE_NOTIFICATION";

export const PARTICIPANT_JOINED_ROUND = "PARTICIPANT_JOINED_ROUND";
export const PARTICIPANT_LEAVED_ROUND = "PARTICIPANT_LEAVED_ROUND";
export const LIST_JOINED_PARTICIPANTS = "LIST_JOINED_PARTICIPANTS";
