import {
  FETCH_REPORT,
  FETCH_OLD_REPORT,
  FETCH_REPORTS,
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  FETCH_OLD_REPORTS,
  FETCH_SCHEMA,
  GENERATE_REPORT,
} from "actions/reports/types";

/**
 * @param state
 * @param action
 */
export default function reports(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case FETCH_REPORTS:
      return { ...action.payload };
    case FETCH_OLD_REPORTS:
    case FETCH_OLD_REPORT:
      return { ...action.payload };
    case CREATE_REPORT:
    case UPDATE_REPORT:
      return { report: action.payload };
    case DELETE_REPORT:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case GENERATE_REPORT:
      return { reportGenerate: action.payload };

    case FETCH_REPORT:
      return { ...state, report: action.payload };

    default:
      return state;
  }
}



export const report = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case FETCH_SCHEMA:
      return { schema: action.payload };
    default:
      return state;
  }
}

