import {
  FETCH_PERMISSIONS,
  CREATE_ROLE,
  FETCH_ROLE,
  UPDATE_ROLE,
  FETCH_ROLES,
} from "actions/roles/types";

/**
 * @param state
 * @param action
 */
export const permissions = (state: Object = {}, action: Object): Object => {
  switch (action.type) {
    case FETCH_PERMISSIONS:
      return { ...action.payload.data };
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export default function roles(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_ROLE:
    case UPDATE_ROLE:
      return { role: action.payload.data, redirect: true };
    case FETCH_ROLE:
      return { role: action.payload.data };
    case FETCH_ROLES:
      return { ...action.payload };
    default:
      return state;
  }
}
