import { mapKeys, omit } from "lodash";
import {
  RECEIVE_NOTIFICATION,
  OPEN_NOTIFICATION,
  LIST_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  LIST_MY_NOTIFICATIONS,
} from "actions/notifications/types";

/**
 * @param state
 * @param action
 */
export default function notifications(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case LIST_NOTIFICATIONS:
      return Object.values(action.payload).length
        ? { notifications: action.payload }
        : state;
    case RECEIVE_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.notification_id]: action.payload.notification,
        },
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: omit(state.notifications, action.payload),
      };
    case OPEN_NOTIFICATION:
      // if (state.notifications) {
      //   return {
      //     ...state,
      //     notifications: state.notifications.filter(
      //       (item) => item.request.episode_id != action.payload
      //     ),
      //   };
      // }
      return state;
    case LIST_MY_NOTIFICATIONS:
      return { ...state, myNotifications: action.payload };
    default:
      return state;
  }
}
