export const FETCH_APPOINTMENTS = "FETCH_APPOINTMENTS";
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENTS";
export const FETCH_APPOINTMENT = "FETCH_APPOINTMENT";
export const EDIT_APPOINTMENT = "EDIT_APPOINTMENTS";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENTS";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";
export const APPOINTMENT_UTILITIES = "APPOINTMENT_UTILITIES";
export const APPOINTMENT_UTILITIES_RESET_CLINICS = "APPOINTMENT_UTILITIES_RESET_CLINICS";
export const LIST_CLINIC_APPOINTMENTS = "LIST_CLINIC_APPOINTMENTS";
export const DOCTOR_CLINICS = "DOCTOR_CLINICS";

export const FETCH_PATIENT_APPOINTMENTS = "FETCH_PATIENT_APPOINTMENTS";
export const PATIENT_CLINIC_SEARCH = "PATIENT_CLINIC_SEARCH";
export const PATIENT_APPOINTMENT_SELECTION = "PATIENT_APPOINTMENT_SELECTION";
export const RESET_PATIENT_APPOINTMENT_SELECTION = "RESET_PATIENT_APPOINTMENT_SELECTION";
export const PATIENT_CLINIC_SLOTS = "PATIENT_CLINIC_SLOTS";
export const PATIENT_APPOINTMENT_LOBBY_CHECK =
  "PATIENT_APPOINTMENT_LOBBY_CHECK";
export const PATIENT_LOAD_ROOM_DATA = "PATIENT_LOAD_ROOM_DATA";
export const PATIENT_ROOM_FINISH = "PATIENT_ROOM_FINISH";

export const PATIENT_FETCH_ATTACHMENTS = "PATIENT_FETCH_ATTACHMENTS";
export const PATIENT_CREATE_ATTACHMENT = "PATIENT_CREATE_ATTACHMENT";
export const PATIENT_FETCH_ATTACHMENT = "PATIENT_FETCH_ATTACHMENT";
export const PATIENT_UPDATE_ATTACHMENT = "PATIENT_UPDATE_ATTACHMENT";
export const PATIENT_DELETE_ATTACHMENT = "PATIENT_DELETE_ATTACHMENT";
export const PATIENT_APPOINTMENT_RESET_DATA = "PATIENT_APPOINTMENT_RESET_DATA";
export const FETCH_APPOINTMENT_PRICE = "FETCH_APPOINTMENT_PRICE";
export const PATIENT_FETCH_APPOINTMENT = "PATIENT_FETCH_APPOINTMENT";
export const PATIENT_FETCH_APPOINTMENT_RECOMMENDATIONS = "PATIENT_FETCH_APPOINTMENT_RECOMMENDATIONS";
export const PATIENT_TIME_LINE = "PATIENT_TIME_LINE";
