import {
  LIST_COORDINATORS,
  CLEAR_COORDINATORS_LIST,
} from "actions/coordinators/types";

/**
 * @param state
 * @param action
 */
export default function coordinators(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case LIST_COORDINATORS:
    case CLEAR_COORDINATORS_LIST:
      return { ...action.payload };
    default:
      return state;
  }
}
