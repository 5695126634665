import { GENERATE_ACCESS_TOKEN } from "actions/communications/types";

/**
 * @param state
 * @param action
 */
export default function communications(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case GENERATE_ACCESS_TOKEN:
      return { token: action.payload.token };
    default:
      return state;
  }
}
