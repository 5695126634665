import {
  USER_LOGIN_SUCCEEDED,
  USER_LOGOUT,
  REFRESH_CURRENT_TOKEN,
  FORGET_PASSWROD,
  RESET_PASSWROD,
  RESEND_ACTIVATION,
  ACTIVATE,
} from "actions/auth/types";

/**
 * @param state
 * @param action
 */
export default function auth(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case FORGET_PASSWROD:
    return {...state,...action.resetredirect };
    break;

    case RESET_PASSWROD:
    case RESEND_ACTIVATION:
    case ACTIVATE:
      return {...state,...action.payload};

    break;
    case USER_LOGIN_SUCCEEDED:
    case REFRESH_CURRENT_TOKEN:
      return { ...state, ...action.payload };
    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
}
