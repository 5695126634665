export const CREATE_QUESTION = "CREATE_QUESTION";
export const FETCH_QUESTION = "FETCH_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const ADD_SUB_QUESTION = "ADD_SUB_QUESTION";
export const DELETE_SUB_QUESTION = "DELETE_SUB_QUESTION";
export const PATIENT_ANSWERED_QUESTIONS = "PATIENT_ANSWERED_QUESTIONS";
export const PATIENT_QUESTION_ANSWERS = "PATIENT_QUESTION_ANSWERS";
export const RESET_STATISTICS = "RESET_STATISTICS";
