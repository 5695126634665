import { START_CLINIC } from "actions/clinics/types";

/**
 * @param state
 * @param action
 */
const clinicStart = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case START_CLINIC:
      return { ...action.payload };
    default:
      return state;
  }
};

export default clinicStart;
