import {
    CREATE_SENT_SURVEY,
    FETCH_SENT_SURVEYS,
    FETCH_SENT_SURVEY,
    UPDATE_SENT_SURVEY,
    DELETE_SENT_SURVEY,
} from "actions/sent-surveys/types";
import { insertItem, updateObjectInArray } from "helpers/helpers";

/**
 * @param state
 * @param action
 */
export default function sentSurveys(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_SENT_SURVEY:
      return {
        ...state,
        data: insertItem(state.data, action.payload.data),
      };
    case UPDATE_SENT_SURVEY:
      return {
        ...state,
        data: updateObjectInArray(state.data, action.payload.data),
        sentSurvey: {},
      };
    case FETCH_SENT_SURVEY:
      return { ...state, sentSurvey: action.payload.data };
    case FETCH_SENT_SURVEYS:
      return { ...action.payload };
    case DELETE_SENT_SURVEY:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}