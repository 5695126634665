export const FETCH_EPISODE_UTILITIES = "FETCH_EPISODE_UTILITIES";
export const CREATE_EPISODE = "CREATE_EPISODE";
export const FETCH_EPISODE = "SHOW_EPISODE";
export const UPDATE_EPISODE = "UPDATE_EPISODE";
export const FETCH_EPISODES = "LIST_EPISODES";
export const DELETE_EPISODE = "DELETE_EPISODE";
export const CLOSE_EPISODE = "CLOSE_EPISODE";
export const SET_DISCHARGE_REASON = "SET_DISCHARGE_REASON";

export const LIST_EPISODE_NOTES = "LIST_EPISODE_NOTES";
export const CREATE_EPISODE_NOTE = "CREATE_EPISODE_NOTE";

export const FETCH_PRESCRIPTION_EXECUTIONS = "FETCH_PRESCRIPTION_EXECUTIONS";
export const CREATE_PRESCRIPTION_EXECUTION = "CREATE_PRESCRIPTION_EXECUTION";

export const FETCH_INVESTIGATIONS = "FETCH_INVESTIGATIONS";
export const FETCH_INVESTIGATION = "FETCH_INVESTIGATION";
export const CREATE_INVESTIGATION = "CREATE_INVESTIGATION";
export const FETCH_CENTER_INVESTIGATIONS = "FETCH_CENTER_INVESTIGATIONS";

export const FETCH_MEDICAL_HISTORY = "FETCH_MEDICAL_HISTORY";
export const UPDATE_MEDICAL_HISTORY = "UPDATE_MEDICAL_HISTORY";

export const GET_EPISODE_CHRONIC_DISEASES = "GET_EPISODE_CHRONIC_DISEASES";
export const GET_EPISODE_CONSULTATIONS = "GET_EPISODE_CONSULTATIONS";
export const GET_EPISODE_DIAGNOSTICS_DATA = "GET_EPISODE_DIAGNOSTICS_DATA";
