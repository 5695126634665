import {
  FETCH_EPISODE_UTILITIES,
  CREATE_EPISODE,
  FETCH_EPISODE,
  UPDATE_EPISODE,
  FETCH_EPISODES,
  DELETE_EPISODE,
  CLOSE_EPISODE,
  GET_EPISODE_CHRONIC_DISEASES,
  GET_EPISODE_CONSULTATIONS,
  GET_EPISODE_DIAGNOSTICS_DATA,
} from "actions/episodes/types";

/**
 * @param state
 * @param action
 */
export const episodeUtilities = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case FETCH_EPISODE_UTILITIES:
    case GET_EPISODE_CHRONIC_DISEASES:
    case GET_EPISODE_DIAGNOSTICS_DATA:
      return { ...state, ...action.payload };
    case GET_EPISODE_CONSULTATIONS:
      return { ...state, consultations: action.payload.data }
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export default function episodes(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_EPISODE:
    case UPDATE_EPISODE:
      return { episode: action.payload.data, redirect: true };
    case FETCH_EPISODE:
      return { episode: action.payload.data };
    case FETCH_EPISODES:
      return { ...action.payload };
    case DELETE_EPISODE:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case CLOSE_EPISODE:
      return { hospitalId: state.episode.hospital_id };
    default:
      return state;
  }
}
