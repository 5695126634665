import {
  FETCH_UTILITIES,
  CREATE_USER,
  FETCH_USER,
  UPDATE_USER,
  FETCH_USERS,
  DELETE_USER,
  FETCH_DOCUMENTS,
  CREATE_DOCUMENT,
  DELETE_DOCUMENT,
  FETCH_USER_PROFILE,
  UPDATE_USER_PROFILE,
  ASSIGN_USER_TO_HOSPITALS,
  GET_ASSIGNED_HOSPITALS
} from "actions/users/types";

/**
 * @param state
 * @param action
 */
export const utilities = (state: Object = {}, action: Object): Object => {
  switch (action.type) {
    case FETCH_UTILITIES:
      return { ...action.payload };
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export default function users(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_USER:
    case UPDATE_USER:
      return { user: action.payload.data, redirect: true };
    case FETCH_USER:
      return { user: action.payload.data };
    case FETCH_USERS:
      return { ...action.payload };
    case DELETE_USER:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case FETCH_USER_PROFILE:
      return { profile: action.payload.data };
    case UPDATE_USER_PROFILE:
      return { profile: action.payload.data, updatedProfile: true};
    case FETCH_DOCUMENTS:
      return { documents: action.payload };
    case CREATE_DOCUMENT:
    case ASSIGN_USER_TO_HOSPITALS:
      return { redirect: true };
    case GET_ASSIGNED_HOSPITALS:
      return {...action.payload };
    case DELETE_DOCUMENT:
      return {
        ...state,
        documents: {
          data: state.documents.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
