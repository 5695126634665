import {
  FETCH_PAST_EPISODES,
  FETCH_PAST_CLINICS,
  FETCH_PAST_LABS,
  FETCH_PAST_RADS,
} from "actions/past-medical-history/types";

/**
 * @param state
 * @param action
 */
export default function pastMedicalHistory(state: Object = {}, action: Object) {
  switch (action.type) {
    case FETCH_PAST_EPISODES:
      return { ...state, episodes: { ...action.payload } };
    case FETCH_PAST_CLINICS:
      return { ...state, clinics: { ...action.payload } };
    case FETCH_PAST_LABS:
      return { ...state, labs: { ...action.payload } };
    case FETCH_PAST_RADS:
      return { ...state, rads: { ...action.payload } };
    default:
      return state;
  }
}
