import {
  CREATE_CITY,
  FETCH_CITY,
  UPDATE_CITY,
  FETCH_CITIES,
  DELETE_CITY,
} from "actions/cities/types";

/**
 * @param state
 * @param action
 */
export default function cities(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_CITY:
    case UPDATE_CITY:
      return { city: action.payload.data, redirect: true };
    case FETCH_CITY:
      return { city: action.payload.data };
    case FETCH_CITIES:
      return { ...action.payload };
    case DELETE_CITY:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}
