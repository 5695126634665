import { FETCH_COUNTRIES } from "actions/cities/types";

/**
 * @param state
 * @param action
 */
export default function countries(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case FETCH_COUNTRIES:
      return { ...action.payload };
    default:
      return state;
  }
}
