import {
  FETCH_PRESCRIPTION_EXECUTIONS,
  CREATE_PRESCRIPTION_EXECUTION,
} from "../../actions/episodes/types";

const prescriptionExecutions = (state: Object = {}, action: Object) => {
  switch (action.type) {
    case FETCH_PRESCRIPTION_EXECUTIONS:
    case CREATE_PRESCRIPTION_EXECUTION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default prescriptionExecutions;
