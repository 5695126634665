import { HIDE_ERROR } from "actions/error/types";

const initState = {
  error: null,
  isOpen: false,
  redirectPath: {},
};

const handleError = (errorObject) => {
  let errorMessage = errorObject.message || null;
  let redirectPathObj = {};
  if (errorObject.response) {
    switch (errorObject.response.status) {
      case 400:
        errorMessage = [errorObject.response.data.error];
        break;
      case 403:
        errorMessage = [
          "Unauthorized: you don't have permissions to do this action",
        ];
        break;
      case 422:
        if (errorObject.response.data?.verify) {
          redirectPathObj = {
            redirectUrl: '/verify-account',
            redirectApi: errorObject.response.data.verify,
          };
          errorMessage = [
            "Account is not verified, Kindly check your email for the activation link.",
          ];
        }
        else {
          errorMessage = Object.values(errorObject.response.data).map(
            (item) => item[0]
          );
        }
        break;
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
        errorMessage = ["Server Error, please conatct Adminstrator"];
        break;

      default:
        errorMessage = ["Network error check your connection"];
    }
  }
  return { error: errorMessage, isOpen: true, redirectPath: redirectPathObj };
};
/**
 * @param state
 * @param action
 */
export default function error(state = initState, action) {
  if (action.error) {
    return handleError(action.error);
  }
  return {
    error: null,
    isOpen: false,
    redirectPath: {},
  };
}
