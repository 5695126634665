import {
  CREATE_LEVEL,
  FETCH_LEVEL,
  UPDATE_LEVEL,
  FETCH_LEVELS,
  DELETE_LEVEL,
} from "actions/levels/types";

/**
 * @param state
 * @param action
 */
export default function levels(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_LEVEL:
    case UPDATE_LEVEL:
      return { level: action.payload.data, redirect: true };
    case FETCH_LEVEL:
      return { level: action.payload.data };
    case FETCH_LEVELS:
      return { ...action.payload };
    case DELETE_LEVEL:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}
