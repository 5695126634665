import {
  CREATE_VACATION,
  FETCH_VACATION,
  UPDATE_VACATION,
  FETCH_VACATIONS,
  DELETE_VACATION,
} from "actions/vacations/types";

/**
 * @param state
 * @param action
 */
export default function vacations(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case CREATE_VACATION:
    case UPDATE_VACATION:
      return { vacation: action.payload.data, redirect: true };
    case FETCH_VACATION:
      return { vacation: action.payload.data };
    case FETCH_VACATIONS:
      return { ...action.payload };
    case DELETE_VACATION:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}
