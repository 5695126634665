import { UTILITIES_LIST_COUNTRIES, UTILITIES_LIST_CITIES } from "actions/commonUtilities/types";

/**
 * @param state
 * @param action
 */
export default function commonUtilities(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case UTILITIES_LIST_COUNTRIES:
      return { ...state, countries: action.payload };
    case UTILITIES_LIST_CITIES:
        return { ...state, cities: action.payload };
    default:
      return state;
  }
}
