import { PAY_BY_FAWRY, PAY_BY_CARD, GET_SIGNATURE, PAYMOB_PAYMENT_INTENTION, PAYMOB_PAYMENT_RESULT_PROCESS } from "actions/payments/types";
import { PATIENT_APPOINTMENT_RESET_DATA } from "actions/appointments/types";

/**
 * @param state
 * @param action
 */
export default function payments(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case GET_SIGNATURE:
      return { signature: action.payload.signature };
    case PAY_BY_FAWRY:
      return { fawry: action.payload };
    case PAY_BY_CARD:
      return { card: action.payload };
    case PAYMOB_PAYMENT_INTENTION:
      return { paymob_intention: action.payload };
    case PAYMOB_PAYMENT_RESULT_PROCESS:
      return { payment_result: action.payload };
    case PATIENT_APPOINTMENT_RESET_DATA:
      return {};
    default:
      return state;
  }
}
