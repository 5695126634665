export const FETCH_PATIENTS = "FETCH_PATIENTS";
export const FETCH_PATIENTS_BASICS = "FETCH_PATIENTS_BASICS";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const FETCH_PATIENT = "FETCH_PATIENT";
export const FETCH_PATIENTS_SURVEY_INSTANCES = "FETCH_PATIENTS_SURVEY_INSTANCES";
export const FETCH_PATIENT_SURVEY_INSTANCE = "FETCH_PATIENT_SURVEY_INSTANCE";
export const CREATE_PATIENT_SURVEY_INSTANCE = "CREATE_PATIENT_SURVEY_INSTANCE";
export const UPDATE_PATIENT_SURVEY_INSTANCE = "UPDATE_PATIENT_SURVEY_INSTANCE";
export const FETCH_SURVEY_UTILITIES = "FETCH_SURVEY_UTILITIES";
export const CREATE_PATIENT_MATCHED_SURVEY_INSTANCE =
  "CREATE_PATIENT_MATCHED_SURVEY_INSTANCE";
