import {
  CREATE_REQUEST,
  ACTIVE_RECEIVED_REQUESTS,
  NEW_RECEIVED_REQUEST,
  START_REQUEST,
  END_REQUEST,
  LIST_ACTIVE_RECEIVED_REQUESTS,
  REJECT_REQUEST,
} from "actions/requests/types";

/**
 * @param state
 * @param action
 */
export default function requests(
  state: Object = { receivedRequests: [] },
  action: Object
): Object {
  switch (action.type) {
    case CREATE_REQUEST:
      return { ...state, request: action.payload.data };
    case ACTIVE_RECEIVED_REQUESTS:
      return { ...state, receivedRequests: action.payload.data };
    case LIST_ACTIVE_RECEIVED_REQUESTS:
      return { ...state, listReceivedRequests: action.payload.data };
    case NEW_RECEIVED_REQUEST:
      return {
        ...state,
        receivedRequests: [
          ...state.receivedRequests.filter(
            (item) => item.id !== action.payload.id
          ),
          action.payload,
        ],
      };
    case START_REQUEST:
      return {
        ...state,
        receivedRequests: state.receivedRequests.filter(
          (item) => item.id !== action.payload.id
        ),
        request: action.payload,
      };
    case END_REQUEST:
    case REJECT_REQUEST:
      return {
        ...state,
        receivedRequests: state.receivedRequests.filter(
          (item) => item.id !== action.payload.id
        ),
        request: null,
      };
    default:
      return state;
  }
}
