import { FETCH_MATCHED_SURVEYES ,FETCH_MATCHED_SURVEYE} from "actions/matched-sent-surveys/types";

/**
 * @param state
 * @param action
 */


export default function  MatchedSentSurveys(
    state: Object = {},
    action: Object
  ): Object {
    switch (action.type) {
      case  FETCH_MATCHED_SURVEYES:
        return { ...action.payload };
        case  FETCH_MATCHED_SURVEYE:
          return {
            ...state,
            survey: action.payload.data
          };
        default:
        return state;
    }
  }
  