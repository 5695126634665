import { TOGGLE_LANGUAGE } from "constants/constants";

/**
 * @param state
 * @param action
 */
export default function language(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case TOGGLE_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}
