import {
  FETCH_PATIENTS,
  DELETE_PATIENT,
  FETCH_PATIENT,
  FETCH_PATIENTS_BASICS,
  FETCH_PATIENTS_SURVEY_INSTANCES,
  FETCH_PATIENT_SURVEY_INSTANCE,
//  CREATE_PATIENT_SURVEY_INSTANCE,
//  UPDATE_PATIENT_SURVEY_INSTANCE,
  FETCH_SURVEY_UTILITIES,
} from "actions/patients/types";

/**
 * @param state
 * @param action
 */
export const surveyUtilities = (
  state: Object = {},
  action: Object
) => {
  switch (action.type) {
    case FETCH_SURVEY_UTILITIES:
    console.log(action.payload);
      return { ...action.payload };
    default:
      return state;
  }
};


/**
 * @param state
 * @param action
 */
export default function patients(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case FETCH_PATIENTS:
      return { ...action.payload };
    case FETCH_PATIENTS_BASICS:
      return { basics: action.payload.data };
    case FETCH_PATIENT:
      return { patient: action.payload.data };
    case DELETE_PATIENT:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case FETCH_PATIENTS_SURVEY_INSTANCES:
      return {data: action.payload.data}
    case FETCH_PATIENT_SURVEY_INSTANCE:
      return {
        ...state,
        survey: action.payload.data
      };
    default:
      return state;
  }
}
