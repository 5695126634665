import { mapKeys, omit } from "lodash";
import {
  CREATE_ROUND,
  FETCH_ROUND,
  UPDATE_ROUND,
  FETCH_ROUNDS,
  DELETE_ROUND,
  FETCH_ROUND_UTILITIES,
  FETCH_MY_ROUNDS,
  FETCH_ROUND_PATIENTS,
  FETCH_PUBLIC_ROUNDS,
  START_ROUND,
  GET_CONFERENCE_DATA,
  FILTER_CASES,
  PARTICIPANT_JOINED_ROUND,
  PARTICIPANT_LEAVED_ROUND,
  LIST_JOINED_PARTICIPANTS,
  SAVE_CONSULTATION_NOTE,
  HIDE_CONSULTATION_NOTE_NOTIFICATION,
  FETCH_USER_ROUND,
} from "actions/rounds/types";
import { FETCH_ATTENDEE_ROUNDS } from "../../actions/rounds/types";

/**
 * @param state
 * @param action
 */
export default function rounds(state: Object = {}, action: Object): Object {
  switch (action.type) {
    case FETCH_ROUNDS:
      return { ...action.payload };
    case FETCH_MY_ROUNDS:
      return { ...mapKeys(action.payload.data, "date") };
      // return { ...action.payload.data };
    case FETCH_PUBLIC_ROUNDS:
      return { ...mapKeys(action.payload.data, "id") };
    case CREATE_ROUND:
    case UPDATE_ROUND:
      return { ...state, [action.payload.data.id]: action.payload.data , redirect: true};
    case FETCH_ROUND:
    case FETCH_USER_ROUND:
    case START_ROUND:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case DELETE_ROUND:
      return omit(state, action.payload);
    default:
      return state;
  }
}

export const roundsUtilities = (state: Object = {}, action: Object): Object => {
  switch (action.type) {
    case FETCH_ROUND_UTILITIES:
      return { ...state, ...action.payload };
    case FETCH_ROUND_PATIENTS:
      return { ...state, roundPatients: action.payload.data };
    case GET_CONFERENCE_DATA:
      return { ...state, ...action.payload };
    case FILTER_CASES:
      return {
        ...state,
        cases: mapKeys(action.payload, "id"),
        notify_consultation_note: false,
      };
    case FETCH_ATTENDEE_ROUNDS:
      return { ...state, attendeeRounds: action.payload.data };
    case PARTICIPANT_JOINED_ROUND: {
      const newParticipants = state.participants;
      newParticipants[action.payload.id] = action.payload;
      return {
        ...state,
        participants: newParticipants,
      };
    }
    case PARTICIPANT_LEAVED_ROUND:
      return {
        ...state,
        participants: omit(state.participants, action.payload),
      };
    case LIST_JOINED_PARTICIPANTS:
      return {
        ...state,
        participants: mapKeys(action.payload, "id"),
      };
    case SAVE_CONSULTATION_NOTE: {
      const caseId = action.payload.case_id;
      const caseNote = action.payload.consultation_note;
      const newCases = state.cases;
      newCases[caseId] = {
        ...state.cases[caseId],
        round_consultation_text: caseNote
      };
      return {
        ...state,
        notify_consultation_note: true,
        cases: newCases,
      };
    }
    case HIDE_CONSULTATION_NOTE_NOTIFICATION:
      return {
        ...state,
        notify_consultation_note: false,
      };
    default:
      return state;
  }
};
