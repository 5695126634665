import {
  FETCH_SPECIALTIES_FORM_UTILITIES,
  CREATE_SPECIALTIES_FORM,
  FETCH_SPECIALTIES_FORM,
  LIST_SPECIALTIES_FORMS,
  LIST_CONDITION_SPECIALTIES_FORMS,
  LIST_ADMIN_SPECIALTIES_FORMS,
  UPDATE_SPECIALTIES_FORM,
  FETCH_SPECIALTIES_FORMS,
  DELETE_SPECIALTIES_FORM,
  RESET_SPECIALTIES_FORM,
  FETCH_PUBLIC_SUBMISSION,
  FETCH_PUBLIC_SUBMISSIONS,
  CREATE_PUBLIC_SUBMISSIONS,
  UPDATE_PUBLIC_SUBMISSIONS,
} from "actions/specialties-form/types";

/**
 * @param state
 * @param action
 */
export const specialtiesFormUtilities = (
  state: Object = {},
  action: Object
) => {
  switch (action.type) {
    case FETCH_SPECIALTIES_FORM_UTILITIES:
      return { ...action.payload };
    default:
      return state;
  }
};

/**
 * @param state
 * @param action
 */
export default function specialtiesForm(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case CREATE_SPECIALTIES_FORM:
    case UPDATE_SPECIALTIES_FORM:
      return { specialtyForm: action.payload.data, redirect: true };
    case FETCH_SPECIALTIES_FORM:
      return { ...state, specialtyForm: action.payload.data };
    case LIST_SPECIALTIES_FORMS:
      return { ...state, specialtyFormsList: action.payload.data };
    case LIST_CONDITION_SPECIALTIES_FORMS:
      return { ...state, specialtyFormsListCon: action.payload.data };
    case LIST_ADMIN_SPECIALTIES_FORMS:
      return { adminSpecialtyFormsList: action.payload.data };
    case RESET_SPECIALTIES_FORM:
      return {};
    case FETCH_SPECIALTIES_FORMS:
      return { ...action.payload };
    case DELETE_SPECIALTIES_FORM:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case FETCH_PUBLIC_SUBMISSIONS:
      return {publicSubmissions: action.payload.data,meta: action.payload.meta};
      case FETCH_PUBLIC_SUBMISSION:
    case CREATE_PUBLIC_SUBMISSIONS:
    case UPDATE_PUBLIC_SUBMISSIONS:
      return { publicSubmission: action.payload.data };
    // case CREATE_SENT_PUBLIC_SURVEY
    default:
      return state;
  }
}
