import {
  CREATE_INVESTIGATION,
  FETCH_INVESTIGATIONS,
  FETCH_INVESTIGATION,
  FETCH_CENTER_INVESTIGATIONS,
} from "actions/episodes/types";

const investigation = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INVESTIGATIONS:
    case CREATE_INVESTIGATION:
      return { ...action.payload };
    case FETCH_CENTER_INVESTIGATIONS:
      return { ...state, centerInvestigations: action.payload.data };
    case FETCH_INVESTIGATION:
      return { ...state, investigation: action.payload.data };
    default:
      return state;
  }
};

export default investigation;
