export const CREATE_HOSPITAL = "CREATE_HOSPITAL";
export const FETCH_HOSPITAL = "SHOW_HOSPITAL";
export const UPDATE_HOSPITAL = "UPDATE_HOSPITAL";
export const FETCH_HOSPITALS = "LIST_HOSPITALS";
export const DELETE_HOSPITAL = "DELETE_HOSPITAL";
export const FETCH_HOSPITAL_STAFF = "FETCH_HOSPITAL_STAFF";
export const FETCH_HOSPITAL_EXCLUDED_USERS = "FETCH_HOSPITAL_EXCLUDED_USERS";
export const MY_HOSPITALS = "MY_HOSPITALS";
export const FETCH_HOSPITAL_LOGO = "FETCH_HOSPITAL_LOGO";
export const FETCH_HOSPITAL_AGREEMENT = "FETCH_HOSPITAL_AGREEMENT";
export const DELETE_HOSPITAL_MEMBER = "DELETE_HOSPITAL_MEMBER";
